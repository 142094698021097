import React from "react"

import { ErrorMessage } from "@hookform/error-message"
import { Stack } from "@mui/material"
import FormHelperText from "@mui/material/FormHelperText"
import TextField from "@mui/material/TextField"
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs, { Dayjs } from "dayjs"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import ErrorBoundary from "@components/ErrorBoundary"
import InvisibleButton from "@components/InvisibleButton"
import HelperText from "@components/forms/default/HelperText"
import { InputContainerDiv } from "@components/forms/default/InputTextField"
import Label from "@components/forms/default/Label"

interface DatepickerProps {
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string
  onChange: (date: any) => void // eslint-disable-line @typescript-eslint/no-explicit-any

  // NOT REQUIRED
  actionFn?: () => void
  dataCy?: string
  defaultValue?: Dayjs
  disabled?: boolean
  errors?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  helperText?: string
  horizontal?: boolean
  label?: string
  loading?: boolean
  noBorder?: boolean
  placeholder?: string
  required?: boolean
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  sxWrapper?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const Datepicker: React.FC<DatepickerProps> = ({
  actionFn,
  control,
  dataCy,
  defaultValue,
  disabled,
  errors,
  helperText,
  horizontal,
  label,
  loading,
  name,
  noBorder,
  onChange,
  placeholder,
  required,
  sx,
  sxWrapper,
}: DatepickerProps) => {
  const { t } = useTranslation()

  const wrapperStyling = horizontal
    ? {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        ...sxWrapper,
      }
    : sxWrapper

  const labelStyling = horizontal
    ? {
        mb: 0,
      }
    : {}

  const inputStyling = horizontal
    ? {
        borderRadius: "12px",
        ml: "auto",
        flex: 1,
        "& input": {
          minHeight: "21px",
          textAlign: "right",
        },
        ...sx,
      }
    : sx

  if (loading) {
    return (
      <InputContainerDiv sx={wrapperStyling} data-cy={dataCy}>
        {label && (
          <Label required={required} sx={labelStyling}>
            {label}
          </Label>
        )}
        {helperText && <HelperText>{helperText}</HelperText>}

        <TextField
          data-testid="skeleton-input"
          size="small"
          sx={{ width: "100%", ...inputStyling }}
          variant="outlined"
          disabled
        />
      </InputContainerDiv>
    )
  }

  return (
    <ErrorBoundary>
      <InputContainerDiv
        style={wrapperStyling}
        sx={{
          "&:hover button": { opacity: 1 },
        }}
        data-cy={dataCy}
      >
        {label && (
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Label required={required} sx={labelStyling}>
              {label}
            </Label>
            {actionFn && (
              <InvisibleButton
                sx={{
                  ml: 2,
                  opacity: 0,
                  transition: "opacity 200ms ease-in",
                }}
                onClick={() => actionFn()}
              >
                {t("edit")}
              </InvisibleButton>
            )}
          </Stack>
        )}
        {helperText && <HelperText>{helperText}</HelperText>}

        <Controller
          control={control}
          defaultValue={defaultValue}
          data-cy={dataCy}
          disabled={disabled}
          name={name}
          render={({ field }) => {
            const dateValue = field.value ? dayjs(field.value) : null

            return (
              <Stack>
                <MuiDatePicker
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: !!errors[name],
                      ...(noBorder && { variant: "standard" }),
                      InputProps: {
                        ...(placeholder && { placeholder }),
                        ...(noBorder && { disableUnderline: true }),
                      },
                    },
                  }}
                  onChange={onChange}
                  sx={inputStyling}
                  value={dateValue}
                />

                <ErrorMessage
                  errors={errors}
                  name={name}
                  render={({ message }) => (
                    <FormHelperText error>{message}</FormHelperText>
                  )}
                />
              </Stack>
            )
          }}
        />
      </InputContainerDiv>
    </ErrorBoundary>
  )
}

export default Datepicker
