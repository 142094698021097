import React, { useEffect } from "react"

import Box from "@mui/material/Box"

import Modal from "@components/Modal"
import { useThemeContext } from "@providers/AppThemeProvider"

interface ReferAndEarnModalProps {
  closeMenu?: () => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ReferAndEarnModal: React.FC<ReferAndEarnModalProps> = ({
  closeMenu,
  open,
  setOpen,
}: ReferAndEarnModalProps) => {
  const { darkMode } = useThemeContext()

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/v2.js"
    document.body.appendChild(script)

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          formId: "8676d755-c6cc-4791-a025-6f107ee965ac",
          portalId: "22481218",
          region: "na1",
          target: "#referHubspotForm",
        })
      }
    })
  }, [])

  return (
    <Modal
      open={open}
      otherClosingActions={closeMenu}
      setOpen={setOpen}
      sx={{
        minWidth: "400px",
        // overwrite darkmode to play nice with Hubspot styles
        backgroundColor: "white",
        button: {
          borderColor: darkMode ? "black" : "inherit",
          color: darkMode ? "black" : "inherit",
        },
      }}
    >
      <Box
        sx={{
          minHeight: "400px",
          minWidth: "500px",
        }}
      >
        <div id="referHubspotForm" />
      </Box>
    </Modal>
  )
}

export default ReferAndEarnModal
