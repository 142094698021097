import React, { useEffect } from "react"

import { SignedIn, SignedOut, useAuth } from "@clerk/clerk-react"
import { useNavigate } from "react-router-dom"

import useSession from "@hooks/useSession"

import routes from "@constants/routes"
import { userStatuses } from "@constants/serverEnums"

import ErrorBoundary from "./ErrorBoundary"

interface ProtectedViewProps {
  children: React.ReactNode
}

const ProtectedView: React.FC<ProtectedViewProps> = ({
  children,
}: ProtectedViewProps) => {
  const { isSignedIn } = useAuth()
  const navigate = useNavigate()

  const { clerkOrg, currentUser, orgMember, loadedClerk } = useSession()
  const currentPath = window.location.pathname

  useEffect(() => {
    if (currentUser?.id) {
      window.analytics.identify(currentUser?.id, {
        email: orgMember?.profile?.email,
        username: currentUser?.username,
        event: "Identify User",
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // checks if user is part of an ORG, needed for users who sign up directly without Sales
  useEffect(() => {
    if (
      loadedClerk &&
      isSignedIn &&
      !clerkOrg && // can be null OR undefined
      !currentPath.includes(routes.NO_ORGANIZATION)
    ) {
      navigate(routes.NO_ORGANIZATION)
    }
  }, [loadedClerk, isSignedIn, currentPath, navigate, clerkOrg])

  // Navigate user to root route if they are not signed in
  if (loadedClerk && !isSignedIn && currentPath !== "/") {
    navigate("/")
  }

  // Navigate user to Access_Denied if they have no org memberships
  if (loadedClerk && orgMember?.profile?.status === userStatuses.SUSPENDED) {
    if (!currentPath.includes(routes.ACCESS_DENIED)) {
      navigate(routes.ACCESS_DENIED)
    }
  }

  // Navigate user with only username and no profile photo to profile builder
  if (loadedClerk && !!orgMember && !orgMember?.profile?.firstLogin) {
    if (!currentPath.includes(routes.PROFILE_BUILDER)) {
      navigate(routes.PROFILE_BUILDER)
    }
  }

  return (
    <ErrorBoundary>
      <SignedIn>{children}</SignedIn>
      <SignedOut>You are logged out</SignedOut>
    </ErrorBoundary>
  )
}

export default ProtectedView
