import { PLACEHOLDER } from "@constants/globalStrings"

export function capitalizeFirstLetter(input: string): string {
  if (input && input?.length === 0) {
    return input
  }

  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
}

export function snakeCaseToDisplayValue(input: string): string {
  return capitalizeFirstLetter(input.replace(/_/g, " "))
}

export function cleanField(field: string): string | null {
  return field === PLACEHOLDER ? null : field
}
