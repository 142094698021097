import React from "react"

import { ErrorMessage } from "@hookform/error-message"
import Autocomplete from "@mui/material/Autocomplete"
import FormHelperText from "@mui/material/FormHelperText"
import MuiSelect from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import { Provinces } from "country-and-province"
import { Controller } from "react-hook-form"

import { InputContainerDiv } from "@components/forms/default/InputTextField"
import Label from "@components/forms/default/Label"

type ProvinceType = {
  code: string
  label: string
}

interface ProvinceSelectProps {
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  defaultCountry?: string
  defaultValue?: string
  errors?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  helperText?: string
  label?: string
  loading?: boolean
  name: string
  watch?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataCy?: any
}

const ProvinceSelect: React.FC<ProvinceSelectProps> = ({
  control,
  defaultCountry,
  defaultValue,
  errors,
  helperText,
  label = "State/Province",
  loading,
  name,
  dataCy,
}: ProvinceSelectProps) => {
  const provincesByCountry = Provinces.byCountryCode(defaultCountry)
  const transformedProvinces: readonly ProvinceType[] = Object.entries(
    provincesByCountry,
  ) // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(([_code, province]) => ({
      code: province.code || province.name,
      label: province.name,
    }))

  const sortedProvinces = [...transformedProvinces].sort((a, b) =>
    a.label.localeCompare(b.label),
  )

  const customFilterOptions = (
    options: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    { inputValue }: { inputValue: string },
  ) => {
    return options.filter((option: ProvinceType) => {
      const optionString = `${option.code} ${option.label}`.toLowerCase()
      const inputValueLower = inputValue.toLowerCase()
      return optionString.includes(inputValueLower)
    })
  }

  if (loading) {
    return (
      <InputContainerDiv>
        <Label>{label}</Label>
        <MuiSelect variant="outlined" fullWidth size="small" disabled />
      </InputContainerDiv>
    )
  }

  return (
    <form noValidate style={{ flex: 1 }}>
      <InputContainerDiv>
        <Label>{label}</Label>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        <Controller
          name={name}
          control={control}
          data-cy={dataCy}
          defaultValue={defaultValue}
          render={({ field }) => {
            if (sortedProvinces?.length > 0) {
              return (
                <Autocomplete
                  disablePortal
                  filterOptions={customFilterOptions}
                  options={sortedProvinces || []}
                  getOptionLabel={(option) =>
                    option?.label ??
                    sortedProvinces?.find(({ code }) => code === option)
                      ?.label ??
                    ""
                  }
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  renderInput={(params) => (
                    <>
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        InputLabelProps={{ children: undefined }}
                        error={Boolean(errors[name])}
                        helperText={helperText}
                        size="small"
                      />
                      <ErrorMessage
                        errors={errors}
                        name={name}
                        render={({ message }) => (
                          <FormHelperText error>{message}</FormHelperText>
                        )}
                      />
                    </>
                  )}
                  onChange={(_event, data) => field.onChange(data?.code ?? "")}
                />
              )
            }

            return (
              <>
                <TextField
                  error={Boolean(errors[name])}
                  size="small"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                />
                <ErrorMessage
                  errors={errors}
                  name={name}
                  render={({ message }) => (
                    <FormHelperText error>{message}</FormHelperText>
                  )}
                />
              </>
            )
          }}
        />
      </InputContainerDiv>
    </form>
  )
}

export default ProvinceSelect
