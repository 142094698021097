interface Node {
  id: string
}

interface Edge {
  node: Node
}

interface PageInfo {
  hasPreviousPage: boolean
}

interface IncomingData {
  edges: Edge[]
  nodes: Node[]
  pageInfo: PageInfo
  totalCount?: number
}

interface ExistingData {
  edges?: Edge[]
  nodes?: Node[]
  pageInfo?: PageInfo
  totalCount?: number
}

export const mergeConnectionResults = (
  existing: ExistingData | undefined,
  incoming: IncomingData,
): IncomingData => {
  const isFirstPage = incoming?.pageInfo?.hasPreviousPage === false

  if (isFirstPage) {
    return incoming
  }

  const merged = {
    totalCount: 0,
    ...existing,
    ...incoming,
  }

  if (existing && existing.edges && incoming && incoming.edges) {
    merged.edges = [...existing.edges, ...incoming.edges]
  } else if (existing && existing.nodes && incoming && incoming.nodes) {
    merged.nodes = [...existing.nodes, ...incoming.nodes]
  }

  if (incoming && incoming.pageInfo) {
    merged.pageInfo = incoming.pageInfo
  }

  if (incoming && incoming.totalCount) {
    merged.totalCount = incoming.totalCount
  }
  return merged
}
