import { useEffect, useState } from "react"

const useHubSpotWidgetLoaded = (): boolean => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false)

  useEffect(() => {
    const checkWidgetLoaded = setInterval(() => {
      if (window.HubSpotConversations) {
        setIsWidgetLoaded(true) // Widget is loaded
        clearInterval(checkWidgetLoaded) // Stop polling
      }
    }, 500) // Check every 500ms

    return () => clearInterval(checkWidgetLoaded) // Cleanup on unmount
  }, [])

  return isWidgetLoaded
}

export default useHubSpotWidgetLoaded
