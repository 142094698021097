/**
 * Utility functions to deal with null and undefined values.
 */

/**
 * If this method is used, it indicates the types are incorrect.
 * Don't use this method, but consider refactoring the code relying on this method so that the types are correct
 */
export function notNullOrQuietError<T>(
  value: T | null | undefined,
  name: string,
): T {
  if (value == null) {
    console.error(
      `Quietly ignoring non-null value ${name}. This is a type issue. Please fix!`,
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return value as unknown as any as T
  }
  return value
}

/**
 * Unsafely cast to another type. If this function is being used we're basically throwing away typescript safety.
 * Ideally use of this function should be removed, because the code is not typesafe (but pretends to be).
 */
export function unsafeCast<DestinationType>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  value: any,
): DestinationType {
  return notNullOrQuietError(
    value,
    JSON.stringify(value),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as unknown as any as DestinationType
}
export function unsafeCastAny(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return unsafeCast<any>(value)
}

/**
 * Remove null/undefined from an array.
 */
export function removeNullish<T>(arr: Array<T | null | undefined>): Array<T> {
  return arr.filter((val: T | null | undefined) => val != null) as Array<T>
}
