import React, { forwardRef } from "react"

import ListItemIcon from "@mui/material/ListItemIcon"
import MenuItem from "@mui/material/MenuItem"
import { useTranslation } from "react-i18next"

import AnchorLink from "@components/AnchorLink"

import { SidebarMenuList, MenuItemProps } from "@constants/features"

interface SidebarMenuItemProps {
  icon: React.ReactNode
  route: string
  text: string
  handleClose: () => void
}

const SidebarMenuItem = ({
  icon,
  route,
  text,
  handleClose,
}: SidebarMenuItemProps) => {
  return (
    <AnchorLink url={route} onClick={() => handleClose()}>
      <MenuItem sx={{ fontSize: "13px" }}>
        <ListItemIcon
          sx={{ minWidth: "25px !important", "& svg": { width: "15px" } }}
        >
          {icon}
        </ListItemIcon>
        {text}
      </MenuItem>
    </AnchorLink>
  )
}

const SidebarMenu = forwardRef<
  HTMLDivElement,
  { id: string; handleClose: () => void }
>(function MySidebar({ id, handleClose, ...props }, ref) {
  const { t } = useTranslation()
  const chosenMenu = SidebarMenuList[id as keyof typeof SidebarMenuList]

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} ref={ref}>
      {(chosenMenu as MenuItemProps[])?.map(
        ({ icon, translatedTextKey, route }: MenuItemProps) => (
          <SidebarMenuItem
            key={translatedTextKey}
            icon={icon}
            text={t(translatedTextKey)}
            route={route}
            handleClose={handleClose}
          />
        ),
      )}
    </div>
  )
})

export default SidebarMenu
