import React, { useEffect, useState } from "react"

import { SignOutButton } from "@clerk/clerk-react"
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined"
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined"
import Logout from "@mui/icons-material/Logout"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined"
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import { useTranslation } from "react-i18next"

import useHubSpotWidgetLoaded from "@hooks/useHubspotWidgetLoaded"
import useSession from "@hooks/useSession"

import AnchorLink from "@components/AnchorLink"
import Avatar from "@components/Avatar"
import ListIconItem from "@components/ListIconItem"

import routes from "@constants/routes"

import ReferAndEarnModal from "./modals/ReferAndEarnModal"
import SuggestFeatureModal from "./modals/SuggestFeatureModal"

import styles from "./NavList.module.css"

interface UserMenuProps {
  open?: boolean
}

const UserMenu: React.FC<UserMenuProps> = ({ open }: UserMenuProps) => {
  const { t } = useTranslation()
  const { orgMember, currentUser } = useSession()
  const isWidgetLoaded = useHubSpotWidgetLoaded()

  const [orgMemberName, setOrgMemberName] = useState(
    orgMember?.profile?.fullName || "Loading...",
  )
  const [orgMemberImage, setOrgMemberImage] = useState(
    orgMember?.profile?.image || "",
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openSuggestFeatureModal, setOpenSuggestFeatureModal] = useState(false)
  const [openReferAndEarnModal, setOpenReferAndEarnModal] = useState(false)
  const [isWidgetRemoved, setIsWidgetRemoved] = useState(true)

  const openWidget = () => {
    if (isWidgetRemoved) {
      window.HubSpotConversations.widget.load({ widgetOpen: true })
      setIsWidgetRemoved(false) // Widget is now loaded in the DOM
    }
  }

  const removeWidget = () => {
    if (!isWidgetRemoved) {
      window.HubSpotConversations.widget.remove()
      setIsWidgetRemoved(true) // Widget is removed from the DOM
    }
  }

  const handleWidgetToggle = () => {
    if (!isWidgetRemoved) {
      removeWidget() // Remove the widget if it's already in the DOM (either open or minimized)
    } else {
      openWidget() // Load and open the widget if it's removed
    }
  }

  // Ensure the HubSpot widget gets hidden after loading
  useEffect(() => {
    if (isWidgetLoaded) {
      window.HubSpotConversations.widget.remove()
    }
  }, [isWidgetLoaded])

  useEffect(() => {
    setOrgMemberName((prevName) => {
      if (orgMember?.profile?.fullName) {
        return orgMember.profile.fullName
      }
      return prevName
    })
  }, [orgMember?.profile?.fullName])

  useEffect(() => {
    setOrgMemberImage((prevImage) => {
      if (orgMember?.profile?.image) {
        return orgMember.profile.image
      }
      return prevImage
    })
  }, [orgMember?.profile?.image])

  const openMenu = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ListItemButton
        aria-controls={openMenu ? "account-menu" : undefined}
        aria-expanded={openMenu ? "true" : undefined}
        aria-haspopup="true"
        className={styles.ListButton}
        data-cy="Drawer-UserMenu"
        id="account-button"
        onClick={handleClick}
      >
        <ListItemIcon sx={{ minWidth: "25px", "& svg": { width: "15px" } }}>
          <Avatar size={16} image={orgMemberImage} />
        </ListItemIcon>
        <ListItemText
          primary={orgMemberName}
          sx={{
            ml: open ? "" : "14px",
            transition: "margin-left 0.2s ease-in-out",
            "& .MuiTypography-body1": {
              fontSize: "12px",
              fontWeight: 500,
            },
          }}
        />
      </ListItemButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "10px",
            border: "1px solid #DFE3E8",
            boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.08)",
          },
        }}
        sx={{ p: 0, "& .MuiList-root": { p: 0.5 } }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuList dense>
          <AnchorLink url={`${routes.USERS}/${currentUser?.id}`}>
            <MenuItem onClick={handleClose}>
              <ListIconItem
                icon={<PersonOutlinedIcon fontSize="small" />}
                primary={t("appMenu_profile")}
              />
            </MenuItem>
          </AnchorLink>
          <MenuItem onClick={handleWidgetToggle}>
            <ListIconItem
              icon={<BugReportOutlinedIcon fontSize="small" />}
              primary={
                isWidgetRemoved
                  ? t("appMenu_reportABug") // Show "Report a Bug" if the widget is removed
                  : t("appMenu_closeChat") // Show "Close Chat" if the widget is loaded, regardless of open/minimized state
              }
            />
          </MenuItem>
          <MenuItem onClick={() => setOpenSuggestFeatureModal(true)}>
            <ListIconItem
              icon={<ReviewsOutlinedIcon fontSize="small" />}
              primary={t("appMenu_suggestAFeature")}
            />
          </MenuItem>
          <MenuItem onClick={() => setOpenReferAndEarnModal(true)}>
            <ListIconItem
              icon={<CardGiftcardOutlinedIcon fontSize="small" />}
              primary={t("appMenu_referAndEarn")}
            />
          </MenuItem>
          <MenuItem>
            <SignOutButton>
              <ListItemButton
                className={styles.LogoutButton}
                data-cy="Logout-Button"
              >
                <ListIconItem
                  icon={<Logout fontSize="small" />}
                  primary={t("appMenu_logOut")}
                />
              </ListItemButton>
            </SignOutButton>
          </MenuItem>
        </MenuList>
      </Menu>

      {openSuggestFeatureModal && (
        <SuggestFeatureModal open setOpen={setOpenSuggestFeatureModal} />
      )}

      {openReferAndEarnModal && (
        <ReferAndEarnModal open setOpen={setOpenReferAndEarnModal} />
      )}
    </>
  )
}

export default UserMenu
