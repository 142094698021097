import React, { useEffect, useState } from "react"

import BarChartIcon from "@mui/icons-material/BarChart"
import ChevronRight from "@mui/icons-material/ChevronRight"
import FactoryIcon from "@mui/icons-material/Factory"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import PersonIcon from "@mui/icons-material/Person"
import SettingsIcon from "@mui/icons-material/Settings"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import useSession from "@hooks/useSession"

import AnchorLink from "@components/AnchorLink"
import ListIconItem from "@components/ListIconItem"
import { LogoIcon, LogoText } from "@components/Logo"
import { useThemeContext } from "@providers/AppThemeProvider"

import { capitalizeFirstLetter } from "@services/modifyStrings"

import { featureNavs, hubs } from "@constants/features"
import routes from "@constants/routes"

import DrawerToggle from "./DrawerToggle"
import SidebarMenu from "./SidebarMenu"
import UserMenu from "./UserMenu"

import styles from "./NavList.module.css"

interface NavListItemProps {
  icon: React.ReactNode
  open: boolean
  primary: string
  to: string
}

const NavListItem: React.FC<NavListItemProps> = ({
  open,
  to,
  icon,
  primary = "",
}) => {
  return (
    <AnchorLink url={to}>
      <Tooltip
        title={open ? "" : capitalizeFirstLetter(primary)}
        placement="right"
      >
        <ListItemButton className={styles.ListButton}>
          <ListIconItem
            icon={icon}
            primary={primary}
            sx={{
              ml: open ? "" : "14px",
              textTransform: "capitalize",
              transition: "margin-left 0.2s ease-in-out",
            }}
          />
        </ListItemButton>
      </Tooltip>
    </AnchorLink>
  )
}

const MenuTooltip = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.common.white
        : theme.palette.common.black,
    border: "1px solid #DFE3E8",
    boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.08)",
    color: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "inherit",
    padding: 0,
  },
}))

interface NavListMenuProps {
  icon: React.ReactNode
  id: string
  open: boolean
  primary: string
}

const NavListMenu: React.FC<NavListMenuProps> = ({
  icon,
  id,
  open,
  primary,
}) => {
  const [openMenu, setOpenMenu] = useState(false)

  const handleClose = () => {
    setOpenMenu(false)
  }

  const handleOpen = () => {
    setOpenMenu(true)
  }

  return (
    <MenuTooltip
      placement="right-start"
      title={<SidebarMenu id={id} handleClose={handleClose} />}
      open={openMenu}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <ListItemButton
        className={styles.ListButton}
        id={id}
        sx={{ textTransform: "capitalize" }}
        onClick={() => handleClose()}
      >
        <ListIconItem
          icon={icon}
          primary={primary}
          sx={{ ml: open ? "" : "14px" }}
        />
        <ChevronRight sx={{ color: "#CED4DB" }} />
      </ListItemButton>
    </MenuTooltip>
  )
}

interface FeatureProps {
  name: string
}

interface NavListProps {
  open: boolean
  toggleDrawer?: () => void
}

const NavList: React.FC<NavListProps> = ({ open, toggleDrawer }) => {
  const { t } = useTranslation()
  const { darkMode } = useThemeContext()
  const { orgMember, canViewReporting, canViewWorkspacePeople } = useSession()
  const gqFeatures = orgMember?.settings?.starredFeatures?.nodes

  const [features, setFeatures] = useState<FeatureProps[]>(gqFeatures || [])

  useEffect(() => {
    setFeatures(gqFeatures || [])
  }, [gqFeatures])

  const SubheaderStyling = {
    backgroundColor: darkMode ? "#2a2929" : "#F9F8F8",
    fontSize: "11px",
    lineHeight: "21px",
    pt: "20px",
  }

  return (
    <>
      <List component="nav">
        <Box
          className={styles.ListButton}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <ListItemIcon sx={{ minWidth: "25px", "& svg": { width: "15px" } }}>
            <LogoIcon style={{ maxHeight: "25px" }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <LogoText
                style={{
                  height: "auto",
                  marginLeft: open ? "0px" : "15px",
                  marginTop: "6px",
                  maxHeight: "21px",
                  transition: "margin-left 0.2s ease-in-out",
                  width: "fit-content",
                }}
              />
            }
            sx={{ m: 0 }}
          />

          {open && (
            <DrawerToggle
              toggleDrawer={toggleDrawer}
              sx={{ position: "absolute", right: "-6px" }}
            />
          )}
        </Box>

        <NavListItem
          open={open}
          to={featureNavs.dashboard.route}
          primary={t(hubs.HOME)}
          icon={featureNavs.dashboard.icon}
        />
      </List>

      {!open && <Divider variant="middle" sx={{ mt: 2, mb: 2 }} />}

      {features.length > 0 && (
        <>
          <List
            component="nav"
            subheader={
              open && (
                <ListSubheader sx={SubheaderStyling}>Starred</ListSubheader>
              )
            }
          >
            {features.map((node: FeatureProps) => {
              const navItem = featureNavs[node?.name]

              if (!navItem) return null

              return (
                <NavListItem
                  icon={navItem.icon}
                  key={navItem.translatedTextKey}
                  open={open}
                  primary={t(navItem.translatedTextKey)}
                  to={navItem.route}
                />
              )
            })}
          </List>

          {!open && <Divider variant="middle" sx={{ mt: 2, mb: 2 }} />}
        </>
      )}

      <List
        sx={{ bgcolor: "inherit" }}
        component="nav"
        aria-labelledby="apps-subheader"
        subheader={
          open && <ListSubheader sx={SubheaderStyling}>Apps</ListSubheader>
        }
      >
        <NavListMenu
          open={open}
          primary={t(hubs.SALES)}
          id={hubs.SALES}
          icon={<FilterAltIcon />}
        />
        <NavListMenu
          open={open}
          primary={t(hubs.FINANCE)}
          id={hubs.FINANCE}
          icon={<MonetizationOnIcon />}
        />
        <NavListMenu
          open={open}
          primary={t(hubs.OPERATIONS)}
          id={hubs.OPERATIONS}
          icon={<FactoryIcon />}
        />
        {canViewWorkspacePeople && (
          <NavListMenu
            open={open}
            primary={t(hubs.PEOPLE)}
            id={hubs.PEOPLE}
            icon={<PersonIcon />}
          />
        )}
        {canViewReporting && (
          <NavListItem
            open={open}
            to={routes.REPORTS}
            primary={t(hubs.REPORTING)}
            icon={<BarChartIcon />}
          />
        )}
      </List>

      <List sx={{ bgcolor: "inherit", mt: "auto" }}>
        <NavListItem
          open={open}
          to={routes.SETTINGS}
          primary={t(hubs.SETTINGS)}
          icon={<SettingsIcon />}
        />
        <UserMenu open={open} />
      </List>
    </>
  )
}

export default NavList
