import React from "react"

import { ErrorMessage } from "@hookform/error-message"
import Chip from "@mui/material/Chip"
import FormHelperText from "@mui/material/FormHelperText"
import InputAdornment from "@mui/material/InputAdornment"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/system"
import { Controller, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"

import InvisibleButton from "@components/InvisibleButton"
import { getCurrencySymbol } from "@components/preloadedSelects/CurrencyAutoComplete"
import { useThemeContext } from "@providers/AppThemeProvider"

import { SxType } from "@gql/sharedTypes"

import { colors } from "@constants/colors"

import Label from "../Label"

export const InputContainerDiv = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
})

interface InputTextFieldProps {
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string

  // NOT REQUIRED
  actionFn?: () => void
  additionalLowerHorizontalLabel?: string
  additionalUpperHorizontalLabel?: string
  chip?: string
  currencyCode?: string
  dataCy?: string
  defaultValue?: string | number
  disabled?: boolean
  endAdornment?: string | React.ReactNode
  endAdornmentLowercase?: boolean
  errors?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  helperText?: string
  horizontal?: boolean
  startAdornment?: string
  label?: string | React.ReactNode
  loading?: boolean
  multiline?: boolean
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  placeholder?: string
  register?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  removeBorder?: boolean
  required?: boolean
  rows?: number
  sx?: SxType
  sxLabel?: SxType
  sxLabelWrapper?: SxType
  sxWrapper?: SxType
  type?: string
  withFieldBg?: boolean
}

const InputTextField: React.FC<InputTextFieldProps> = ({
  actionFn,
  additionalLowerHorizontalLabel,
  additionalUpperHorizontalLabel,
  chip,
  control,
  currencyCode,
  dataCy,
  defaultValue = "",
  disabled,
  endAdornment,
  endAdornmentLowercase = true,
  errors = [],
  helperText,
  horizontal,
  startAdornment,
  label,
  loading,
  multiline,
  name,
  onBlur = () => {},
  onKeyDown = () => {},
  onChange,
  placeholder,
  register = () => ({}),
  removeBorder,
  required,
  rows,
  sx,
  sxLabel,
  sxLabelWrapper,
  sxWrapper,
  type,
  withFieldBg,
}: InputTextFieldProps) => {
  const { t } = useTranslation()
  const { darkMode } = useThemeContext()
  const disabledBg = darkMode ? colors.BG_DARK_GRAY : colors.BG_LIGHT_GRAY

  const watchedValue = useWatch({
    control,
    name,
    defaultValue,
  })

  const wrapperStyling = horizontal
    ? {
        backgroundColor: disabled && withFieldBg ? disabledBg : "inherit",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        ...sxWrapper,
      }
    : sxWrapper
  const labelStyling = horizontal
    ? {
        mb: 0,
        ...sxLabel,
      }
    : sxLabel
  const inputStyling = horizontal
    ? {
        backgroundColor: disabled ? disabledBg : "inherit",
        borderRadius: "12px",
        ml: "auto",
        flex: 1,
        "& input": {
          minHeight: "21px",
          textAlign: "right",
        },
        "& input[type=number]": {
          MozAppearance: "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        ...sx,
      }
    : {
        backgroundColor: disabled ? disabledBg : "inherit",
        "& input[type=number]": {
          MozAppearance: "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        ...sx,
      }

  const startInputAdornment = (currencyCode || startAdornment) && (
    <InputAdornment position="start" sx={{ pt: "1px" }}>
      {currencyCode && getCurrencySymbol(currencyCode)}
      {startAdornment && startAdornment}
    </InputAdornment>
  )

  const endInputAdornment = endAdornment && (
    <InputAdornment
      position="end"
      sx={{ textTransform: endAdornmentLowercase ? "lowercase" : "none" }}
    >
      {endAdornment && endAdornment}
    </InputAdornment>
  )

  if (loading) {
    return (
      <InputContainerDiv style={wrapperStyling}>
        <Stack
          sx={
            horizontal
              ? { width: "50%", pr: 4, ...sxLabelWrapper }
              : sxLabelWrapper
          }
        >
          {label && (
            <Label required={required} sx={labelStyling}>
              {label}{" "}
              {chip && (
                <Chip
                  label={chip}
                  size="small"
                  sx={{ fontSize: "10px", height: "20px", ml: 1.5 }}
                />
              )}
            </Label>
          )}
          {helperText && (
            <FormHelperText sx={{ mb: 0.5 }}>{helperText}</FormHelperText>
          )}
        </Stack>

        <Stack>
          {additionalUpperHorizontalLabel && horizontal && (
            <Typography variant="caption">
              {additionalUpperHorizontalLabel}
            </Typography>
          )}
          <TextField
            data-testid="skeleton-input"
            InputProps={{
              endAdornment: endInputAdornment,
              startAdornment: startInputAdornment,
              sx: removeBorder
                ? {
                    p: 0,
                    "& fieldset": {
                      border: "none",
                      p: 0,
                    },
                  }
                : {},
            }}
            size="small"
            sx={{ width: "100%", ...inputStyling }}
            variant="outlined"
            disabled
          />
        </Stack>
      </InputContainerDiv>
    )
  }

  return (
    <InputContainerDiv
      style={wrapperStyling}
      sx={{
        "&:hover button": { opacity: 1 },
      }}
    >
      {(label || helperText) && (
        <Stack
          sx={
            horizontal
              ? { width: "50%", pr: 4, ...sxLabelWrapper }
              : sxLabelWrapper
          }
        >
          {label && (
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Label required={required} sx={labelStyling}>
                {label}{" "}
                {chip && (
                  <Chip
                    label={chip}
                    size="small"
                    sx={{ fontSize: "10px", height: "20px", ml: 1.5 }}
                  />
                )}
              </Label>

              {actionFn && (
                <InvisibleButton
                  sx={{
                    ml: 2,
                    opacity: 0,
                    transition: "opacity 200ms ease-in",
                  }}
                  onClick={() => actionFn()}
                >
                  {t("edit")}
                </InvisibleButton>
              )}
            </Stack>
          )}
          {helperText && (
            <FormHelperText sx={{ mb: 0.5 }}>{helperText}</FormHelperText>
          )}
        </Stack>
      )}

      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        key={name}
        render={({ field }) => (
          <Stack>
            {additionalUpperHorizontalLabel && horizontal && (
              <Typography variant="caption" sx={{ ml: "auto", mb: 0.5 }}>
                {additionalUpperHorizontalLabel}
              </Typography>
            )}
            <TextField
              autoComplete="off"
              data-cy={dataCy}
              data-testid="textfield-input"
              disabled={disabled}
              error={!!errors[name]}
              InputProps={{
                endAdornment: endInputAdornment,
                startAdornment: startInputAdornment,
                sx: removeBorder
                  ? {
                      p: 0,
                      "& fieldset": {
                        border: "none",
                        p: 0,
                      },
                    }
                  : {},
              }}
              multiline={multiline}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              rows={rows}
              size="small"
              sx={{ width: "100%", ...inputStyling }}
              type={type}
              variant="outlined"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(name)}
              onBlur={onBlur}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => {
                field.onChange(e)
                onChange?.(e)
              }}
              value={watchedValue || field.value}
            />

            {additionalLowerHorizontalLabel && horizontal && (
              <Typography variant="caption" sx={{ ml: "auto", mt: 1 }}>
                {additionalLowerHorizontalLabel}
              </Typography>
            )}

            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <FormHelperText
                  error
                  sx={{
                    textAlign: horizontal ? "right" : "left",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </Stack>
        )}
      />
    </InputContainerDiv>
  )
}

export default InputTextField
