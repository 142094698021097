/**
 * Join an array of CSS class names into a single string.
 *
 * @param {...string} styles - An array of CSS class names to join.
 * @returns {string} - The joined CSS class names as a single string.
 *
 * @example
 * joinStyles(styles.One, styles.Two, isActive && styles.Three);
 * // "One Two" if isActive === true or "One Two Three" if isActive === false
 */

export default function joinStyles(...styles: string[]): string {
  return styles.filter(Boolean).join(" ")
}
