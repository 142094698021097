import React, { createContext, useContext, useState, useMemo } from "react"

import ls, { localKeys } from "@services/localStorage"

interface AppDrawerContextType {
  open: boolean
  toggleDrawer: () => void
}

const AppDrawerContext = createContext<AppDrawerContextType | undefined>(
  undefined,
)

export const useAppDrawer = (): AppDrawerContextType => {
  const context = useContext(AppDrawerContext)
  if (context === undefined) {
    throw new Error("useAppDrawer must be used within an AppDrawerProvider")
  }
  return context
}

interface AppDrawerProviderProps {
  children: React.ReactNode
}

export const AppDrawerProvider: React.FC<AppDrawerProviderProps> = ({
  children,
}) => {
  const drawerState =
    ls.getItem(localKeys.DRAWER) === null ? true : ls.getItem(localKeys.DRAWER)
  const [open, setOpen] = useState(Boolean(drawerState))

  const memoizedValue = useMemo(() => {
    const toggleDrawer = () => {
      ls.setItem(localKeys.DRAWER, !open)
      setOpen(!open)
    }

    return { open, toggleDrawer }
  }, [open])

  return (
    <AppDrawerContext.Provider value={memoizedValue}>
      {children}
    </AppDrawerContext.Provider>
  )
}
