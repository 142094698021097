import React, { useState } from "react"

import { ErrorMessage } from "@hookform/error-message"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import FormHelperText from "@mui/material/FormHelperText"
import InputAdornment from "@mui/material/InputAdornment"
import MenuItem from "@mui/material/MenuItem"
import MuiSelect from "@mui/material/Select"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import FetchMore from "@components/FetchMore"
import InfoTooltip from "@components/InfoTooltip"
import InvisibleButton from "@components/InvisibleButton"
import Pill from "@components/Pill"
import { InputContainerDiv } from "@components/forms/default/InputTextField"
import Label from "@components/forms/default/Label"

import { colors } from "@constants/colors"
import { PLACEHOLDER } from "@constants/globalStrings"

export interface ItemProps {
  label: string
  value: string

  deleted?: boolean
  disabled?: boolean
  disabledReason?: string
  helperText?: string
  icon?: React.ReactNode
  info?: string
}

export interface SelectProps {
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  errors: any // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string
  options: ItemProps[]

  // NOT REQUIRED
  actionFn?: () => void
  dataCy?: string
  defaultValue?: string | string[]
  disabled?: boolean
  handleChange?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  helperText?: string | React.ReactNode
  helperTextEditOptions?: string
  horizontal?: boolean
  label?: string
  loading?: boolean
  multiple?: boolean
  noBorder?: boolean
  placeholder?: string
  required?: boolean
  setEditOptionsModal?: React.Dispatch<React.SetStateAction<boolean>>
  editOptionsText?: string
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  sxLabel?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  sxListItem?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  sxWrapper?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  tooltip?: string
  usePill?: boolean
  fetchMoreOptions?: () => void
  hasMoreOptions?: boolean
  loadingMoreOptions?: boolean
}

const Select: React.FC<SelectProps> = ({
  actionFn,
  control,
  dataCy,
  defaultValue,
  disabled,
  errors,
  handleChange,
  helperText,
  helperTextEditOptions,
  horizontal,
  label,
  loading = false,
  multiple,
  name,
  noBorder = false,
  options,
  placeholder,
  required,
  setEditOptionsModal,
  editOptionsText,
  sx,
  sxLabel,
  sxListItem,
  sxWrapper,
  tooltip,
  usePill,
  fetchMoreOptions,
  hasMoreOptions,
  loadingMoreOptions,
}: SelectProps) => {
  const { t } = useTranslation()
  const [openSelect, setOpenSelect] = useState(false)

  const handleClose = () => {
    setOpenSelect(false)
  }

  const handleOpen = () => {
    setOpenSelect(true)
  }

  const wrapperStyling = horizontal
    ? {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        "&:hover button": { opacity: 1 },
        ...sxWrapper,
      }
    : {
        "&:hover button": { opacity: 1 },
        ...sxWrapper,
      }
  const labelStyling = horizontal
    ? {
        mb: 0,
        ...sxLabel,
      }
    : sxLabel
  const selectStyling = horizontal
    ? {
        ml: "auto",
        flex: 1,
        "& fieldset": {
          border: noBorder ? "none" : "auto",
        },
        textAlign: "right",
        ...sx,
      }
    : sx

  return (
    <InputContainerDiv sx={wrapperStyling} data-cy={dataCy}>
      {label && (
        <Stack sx={horizontal ? { width: "50%", pr: 4 } : {}}>
          <Stack direction="row">
            <Label sx={labelStyling} required={required}>
              {label}
            </Label>
            {tooltip && <InfoTooltip sx={{ ml: 1 }} text={tooltip} />}

            {actionFn && (
              <InvisibleButton
                sx={{ ml: 2, opacity: 0, transition: "opacity 200ms ease-in" }}
                onClick={() => actionFn()}
              >
                {t("edit")}
              </InvisibleButton>
            )}
          </Stack>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Stack>
      )}
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field }) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const handleSelectChange = (event: any) => {
            field.onChange(event)

            if (handleChange) {
              const selectedOption = options.find(
                (item) => item.value === event.target.value,
              )

              handleChange(event, selectedOption?.label, selectedOption)
            }
          }

          return (
            <Box
              className="DigitSelect"
              sx={
                horizontal
                  ? { display: "flex", flexDirection: "column", width: "50%" }
                  : { width: "inherit" }
              }
            >
              <MuiSelect
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                data-cy={dataCy}
                disabled={disabled}
                displayEmpty
                endAdornment={
                  loading && (
                    <InputAdornment position="end" sx={{ mr: 3 }}>
                      <CircularProgress color="inherit" size={20} />
                    </InputAdornment>
                  )
                }
                error={errors[name] !== undefined}
                fullWidth
                multiple={multiple}
                onChange={handleSelectChange}
                onClose={handleClose}
                onOpen={handleOpen}
                open={openSelect}
                required={required}
                size="small"
                sx={selectStyling}
                variant="outlined"
              >
                {placeholder && (
                  <MenuItem disabled sx={sxListItem} value={PLACEHOLDER}>
                    <Typography color={colors.TEXT_LIGHT_GRAY}>
                      {placeholder}
                    </Typography>
                  </MenuItem>
                )}

                {options.map((item: ItemProps, index: number) => (
                  <MenuItem
                    disabled={item?.disabled || item?.deleted}
                    key={item.value}
                    value={item.value || ""}
                    divider={
                      // show divider only if this is the last item and there is an edit options btn
                      index === options.length - 1 && !!setEditOptionsModal
                    }
                    sx={{
                      height: item.deleted ? 0 : "auto",
                      p: item.deleted ? 0 : "auto",
                      overflow: item.deleted ? "hidden" : "inherit",
                      ...sxListItem,
                    }}
                  >
                    {usePill ? (
                      <Pill
                        status={item.label}
                        sx={{ ml: horizontal ? "auto" : "" }}
                      />
                    ) : (
                      <Stack>
                        <Stack
                          direction="row"
                          gap={4}
                          sx={{
                            alignItems: "center",
                            width: "100%",
                            justifyContent: horizontal ? "end" : "start",
                          }}
                        >
                          <Stack
                            direction="row"
                            gap={1}
                            sx={{
                              alignItems: "center",
                            }}
                          >
                            {item.icon}
                            <Typography
                              sx={{
                                opacity: item.deleted ? 0.5 : 1,
                                textDecoration: item.deleted
                                  ? "line-through"
                                  : "inherit",
                              }}
                            >
                              {item.label}
                            </Typography>
                          </Stack>

                          {item.disabled && item.disabledReason && (
                            <Pill
                              color="orange"
                              label={item.disabledReason}
                              status={item.disabledReason}
                              sx={{ ml: "auto" }}
                            />
                          )}
                        </Stack>

                        {item.helperText && (
                          <Typography
                            sx={{
                              opacity: 0.7,
                              textWrap: "wrap",
                            }}
                            variant="caption"
                          >
                            {item.helperText}
                          </Typography>
                        )}
                      </Stack>
                    )}
                  </MenuItem>
                ))}

                {setEditOptionsModal && (
                  <MenuItem
                    onClick={() => {
                      setEditOptionsModal(true)
                      setOpenSelect(false)
                    }}
                    sx={{
                      alignItems: "flex-start",
                      flexDirection: "column",
                      ...sxListItem,
                    }}
                  >
                    <Typography
                      color={colors.SECONDARY}
                      variant="body2"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <EditOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                      {editOptionsText ?? t("editOptions")}
                    </Typography>
                    {helperTextEditOptions && (
                      <Typography
                        sx={{
                          mt: 0.5,
                          opacity: 0.6,
                          textWrap: "wrap",
                        }}
                        variant="caption"
                      >
                        {helperTextEditOptions}
                      </Typography>
                    )}
                  </MenuItem>
                )}
                {fetchMoreOptions && hasMoreOptions && (
                  <FetchMore
                    fetchMoreData={fetchMoreOptions}
                    hasMore={hasMoreOptions}
                    loading={loadingMoreOptions || false}
                  />
                )}
              </MuiSelect>
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => {
                  return (
                    <FormHelperText
                      error
                      sx={{ textAlign: horizontal ? "right" : "left" }}
                    >
                      {message}
                    </FormHelperText>
                  )
                }}
              />
            </Box>
          )
        }}
      />
    </InputContainerDiv>
  )
}

export default Select
