import React, { createContext, useEffect, ReactNode, useMemo } from "react"

import { useLocation } from "react-router-dom"

const AnalyticsContext = createContext({})

interface AnalyticsProviderProps {
  children: ReactNode
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const location = useLocation()

  useEffect(() => {
    window.analytics.page({
      referrer: window.location.origin,
      url: location.pathname,
    })
  }, [location.pathname])

  const contextValue = useMemo(() => ({}), [])

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  )
}
