import React from "react"

import ViewSidebarOutlinedIcon from "@mui/icons-material/ViewSidebarOutlined"
import MuiAppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { useAppDrawer } from "@providers/AppDrawerProvider"

import { drawerWidth, closedDrawerWidth } from "./Drawer"
import TabsRouter from "./TabsRouter"

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "light" ? "#FFFFFF" : theme.palette.grey[900],
  borderBottom: "1px solid #DFE3E8",
  boxShadow: "none",
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[900]
      : theme.palette.grey[100],
  paddingTop: "8px",
}))

const IconWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.primary.main : "#555",
}))

interface AppbarProps {
  action?: React.ReactNode
  disabled?: boolean
  hub?: string
  favoriteFeature?: string
}

const Appbar: React.FC<AppbarProps> = ({
  action,
  disabled,
  hub,
  favoriteFeature,
}: AppbarProps) => {
  const { open, toggleDrawer } = useAppDrawer()

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          left: open ? drawerWidth : closedDrawerWidth,
          width: `calc(100% - ${open ? drawerWidth : closedDrawerWidth}px)`,
          zIndex: 998,
        }}
      >
        <Toolbar
          variant="dense"
          sx={{
            pr: "24px", // keep right padding when drawer closed
            justifyItems: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", alignItems: "center", height: "30px" }}>
              {!open && (
                <IconWrapper>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="toggle drawer"
                    onClick={() => toggleDrawer()}
                    sx={{
                      marginRight: "9px",
                      p: 0.7,
                    }}
                  >
                    <ViewSidebarOutlinedIcon sx={{ maxHeight: "18px" }} />
                  </IconButton>
                </IconWrapper>
              )}

              <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
                {hub}
              </Typography>
            </Box>

            {!disabled && (
              <TabsRouter hub={hub} favoriteFeature={favoriteFeature} />
            )}
          </Box>
          {action && <Box sx={{ ml: "auto" }}>{action}</Box>}
        </Toolbar>
      </AppBar>

      <Toolbar />
    </>
  )
}

export default Appbar
