import React from "react"

import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"

interface ListIconItemProps {
  icon: React.ReactNode
  primary: string
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const ListIconItem: React.FC<ListIconItemProps> = ({
  icon,
  primary,
  sx,
}: ListIconItemProps) => (
  <>
    <ListItemIcon sx={{ minWidth: "25px", "& svg": { width: "15px" } }}>
      {icon}
    </ListItemIcon>
    <ListItemText
      primary={primary}
      sx={{
        "& .MuiTypography-body1": {
          fontSize: "12px",
          fontWeight: 500,
        },
        ...sx,
      }}
    />
  </>
)

export default ListIconItem
