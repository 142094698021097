import React, { useEffect } from "react"

import { Box, CircularProgress } from "@mui/material"

import useInView from "@hooks/useInView"

interface FetchMoreProps {
  fetchMoreData: () => void
  hasMore: boolean
  loading: boolean
}

const FetchMore: React.FC<FetchMoreProps> = ({
  fetchMoreData,
  hasMore,
  loading,
}) => {
  const [isInView, fetchMoreRef] = useInView<HTMLDivElement>()

  useEffect(() => {
    if (isInView && !loading && hasMore) {
      fetchMoreData()
    }
  }, [fetchMoreData, hasMore, isInView, loading])

  return (
    <div ref={fetchMoreRef}>
      {hasMore && (
        <Box sx={{ justifyContent: "center", display: "flex" }}>
          <CircularProgress size={24} />
        </Box>
      )}
    </div>
  )
}

export default FetchMore
