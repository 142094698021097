import React, { useState, useEffect } from "react"

import { useMutation } from "@apollo/client"
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded"
import StarRoundedIcon from "@mui/icons-material/StarRounded"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"

import useSession from "@hooks/useSession"

import { notNullOrQuietError } from "@utils/null"

import {
  StarFeatureDocument,
  UnstarFeatureDocument,
  Feature,
} from "@gql/graphql"

const CustomTooltip = () => {
  const { t } = useTranslation()

  return (
    <Box sx={{ maxWidth: "150px", p: 0.5 }}>
      <Typography variant="body2">{t("favoriteFeature_tooltip")}</Typography>
    </Box>
  )
}

interface FavoriteFeatureProps {
  feature: string
}

const FavoriteFeature: React.FC<FavoriteFeatureProps> = ({
  feature,
}: FavoriteFeatureProps) => {
  const { t } = useTranslation()
  const { clerkOrg, orgMember, refetchData } = useSession()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [starredFeatures, setStarredFeatures] = useState<any>(
    orgMember?.settings?.starredFeatures?.nodes || [],
  )

  const isStarred = starredFeatures?.some((node: Feature) => {
    return node?.name === feature
  })

  const [starFeature] = useMutation(StarFeatureDocument)
  const [unStarFeature] = useMutation(UnstarFeatureDocument)

  useEffect(() => {
    setStarredFeatures(orgMember?.settings?.starredFeatures?.nodes)
  }, [starredFeatures]) // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleStarClick = (e: any) => {
    const selectedFeature = e.currentTarget.name

    const mutationArgs = {
      input: {
        clerkOrganizationId: notNullOrQuietError(
          clerkOrg?.id,
          "clerkOrganizationId",
        ),
        feature: selectedFeature,
      },
    }

    if (clerkOrg?.id) {
      starFeature({
        variables: mutationArgs,
        onCompleted: () => {
          refetchData() // refetch latest starred features for drawer
        },
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUnstarClick = (e: any) => {
    const selectedFeature = e.currentTarget.name

    const mutationArgs = {
      input: {
        clerkOrganizationId: notNullOrQuietError(
          clerkOrg?.id,
          "clerkOrganizationId",
        ),
        feature: selectedFeature,
      },
    }

    if (clerkOrg?.id) {
      unStarFeature({
        variables: mutationArgs,
        onCompleted: () => {
          refetchData() // refetch latest starred features for drawer
        },
      })
    }
  }

  return (
    <Tooltip title={<CustomTooltip />} placement="bottom" arrow>
      {isStarred ? (
        <Button
          aria-label="unfavorite feature"
          name={feature}
          onClick={handleUnstarClick}
          variant="text"
        >
          <StarRoundedIcon sx={{ width: "18px" }} />
          <Typography variant="body2" sx={{ ml: 1 }}>
            {t("favoriteFeature_removeFromStarred")}
          </Typography>
        </Button>
      ) : (
        <Button
          aria-label="favorite feature"
          name={feature}
          onClick={handleStarClick}
          variant="text"
        >
          <StarOutlineRoundedIcon sx={{ width: "18px" }} />
          <Typography variant="body2" sx={{ ml: 1 }}>
            {t("favoriteFeature_addToStarred")}
          </Typography>
        </Button>
      )}
    </Tooltip>
  )
}

export default FavoriteFeature
