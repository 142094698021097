import React from "react"

import Box from "@mui/material/Box"

import Appbar from "./Appbar"
import Drawer from "./Drawer"

const SkeletonLayout: React.FC = () => (
  <>
    <Drawer />
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Appbar />
    </Box>
  </>
)

export default SkeletonLayout
