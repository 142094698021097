import React from "react"

import { ErrorMessage } from "@hookform/error-message"
import { Typography } from "@mui/material"
import Chip from "@mui/material/Chip"
import FormHelperText from "@mui/material/FormHelperText"
import InputAdornment from "@mui/material/InputAdornment"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/system"
import { Controller, useWatch } from "react-hook-form"
import {
  NumericFormat,
  NumericFormatProps,
  NumberFormatValues,
} from "react-number-format"

import { getCurrencySymbol } from "@components/preloadedSelects/CurrencyAutoComplete"

import Label from "../Label"

interface CustomProps {
  currencyCode: string
  fixedDecimalScale?: boolean
  isCurrency: boolean
  name: string
  numDecimalPlaces?: number
  onChange: (event: { target: { name: string; value: string } }) => void
}
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const {
      currencyCode,
      fixedDecimalScale,
      isCurrency,
      numDecimalPlaces,
      onChange,
      ...other
    } = props
    const currencySymbol = isCurrency ? getCurrencySymbol(currencyCode) : ""
    const [isFocused, setIsFocused] = React.useState(false)

    return (
      <NumericFormat
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        getInputRef={ref}
        onValueChange={(values: NumberFormatValues) => {
          if (isFocused) {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            })
          }
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        thousandSeparator
        valueIsNumericString
        decimalScale={numDecimalPlaces ?? (isCurrency ? 2 : 5)}
        fixedDecimalScale={!!fixedDecimalScale}
        prefix={`${currencySymbol} `}
      />
    )
  },
)

export const InputNumericFieldContainerDiv = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
})
interface InputNumericFieldProps {
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  errors: any // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string

  // NOT REQUIRED
  additionalLowerHorizontalLabel?: string
  additionalUpperHorizontalLabel?: string
  chip?: string
  currencyCode?: string
  dataCy?: string
  defaultValue?: string
  disabled?: boolean
  endAdornment?: string
  fixedDecimalScale?: boolean
  helperText?: string
  horizontal?: boolean
  isCurrency?: boolean
  label?: string
  loading?: boolean
  multiline?: boolean
  numDecimalPlaces?: number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  register?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  removeBorder?: boolean
  required?: boolean
  rows?: number
  startAdornment?: string
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  sxLabel?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  sxLabelWrapper?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  sxWrapper?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const InputNumericField: React.FC<InputNumericFieldProps> = ({
  additionalLowerHorizontalLabel,
  additionalUpperHorizontalLabel,
  chip,
  control,
  currencyCode,
  dataCy,
  defaultValue = "",
  disabled,
  endAdornment,
  errors = [],
  fixedDecimalScale,
  helperText,
  horizontal,
  isCurrency,
  label,
  loading,
  multiline,
  name,
  numDecimalPlaces,
  onChange,
  onKeyDown = () => {},
  placeholder,
  register = () => {},
  removeBorder,
  required,
  rows,
  startAdornment,
  sx,
  sxLabel,
  sxLabelWrapper,
  sxWrapper,
}: InputNumericFieldProps) => {
  const watchedValue = useWatch({
    control,
    name,
    defaultValue,
  })

  const wrapperStyling = horizontal
    ? {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        ...sxWrapper,
      }
    : sxWrapper
  const labelStyling = horizontal
    ? {
        mb: 0,
        ...sxLabel,
      }
    : sxLabel
  const inputStyling = horizontal
    ? {
        ml: "auto",
        flex: 1,
        "& input": {
          textAlign: "right",
        },
        ...sx,
      }
    : sx

  const startInputAdornment = (currencyCode || startAdornment) && (
    <InputAdornment position="start">
      {startAdornment && startAdornment}
    </InputAdornment>
  )

  const endInputAdornment = endAdornment && (
    <InputAdornment position="end" sx={{ textTransform: "lowercase" }}>
      {endAdornment && endAdornment}
    </InputAdornment>
  )

  if (loading) {
    return (
      <InputNumericFieldContainerDiv style={wrapperStyling}>
        <Stack
          sx={
            horizontal
              ? { width: "50%", pr: 4, ...sxLabelWrapper }
              : sxLabelWrapper
          }
        >
          {label && (
            <Label required={required} sx={labelStyling}>
              {label}{" "}
              {chip && (
                <Chip
                  label={chip}
                  size="small"
                  sx={{ fontSize: "10px", height: "20px", ml: 1.5 }}
                />
              )}
            </Label>
          )}
          {helperText && (
            <FormHelperText sx={{ mb: 0.5 }}>{helperText}</FormHelperText>
          )}
        </Stack>
        <Stack>
          {additionalUpperHorizontalLabel && horizontal && (
            <Typography variant="caption">
              {additionalUpperHorizontalLabel}
            </Typography>
          )}
          <TextField
            data-testid="skeleton-input"
            InputProps={{
              endAdornment: endInputAdornment,
              startAdornment: startInputAdornment,
              sx: removeBorder
                ? {
                    p: 0,
                    "& fieldset": {
                      border: "none",
                      p: 0,
                    },
                  }
                : {},
            }}
            size="small"
            sx={{ width: "100%", ...inputStyling }}
            variant="outlined"
            disabled
          />
        </Stack>
      </InputNumericFieldContainerDiv>
    )
  }

  return (
    <InputNumericFieldContainerDiv style={wrapperStyling}>
      <Stack
        sx={
          horizontal
            ? { width: "50%", pr: 4, ...sxLabelWrapper }
            : sxLabelWrapper
        }
      >
        {label && (
          <Label required={required} sx={labelStyling}>
            {label}{" "}
            {chip && (
              <Chip
                label={chip}
                size="small"
                sx={{ fontSize: "10px", height: "20px", ml: 1.5 }}
              />
            )}
          </Label>
        )}
        {helperText && (
          <FormHelperText sx={{ mb: 0.5 }}>{helperText}</FormHelperText>
        )}
      </Stack>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        key={name}
        data-cy={dataCy}
        render={({ field }) => {
          return (
            <Stack>
              {additionalUpperHorizontalLabel && horizontal && (
                <Typography variant="caption" sx={{ ml: "auto", mb: 0.5 }}>
                  {additionalUpperHorizontalLabel}
                </Typography>
              )}
              <TextField
                data-testid="currencyfield-input"
                disabled={disabled}
                data-cy={dataCy}
                error={!!errors[name]}
                InputProps={{
                  inputProps: {
                    currencyCode,
                    isCurrency,
                    onChange,
                    numDecimalPlaces,
                    fixedDecimalScale,
                  },
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent: NumericFormatCustom as any,
                  endAdornment: endInputAdornment,
                  startAdornment: startInputAdornment,
                  sx: removeBorder
                    ? {
                        p: 0,
                        "& fieldset": {
                          border: "none",
                          p: 0,
                        },
                      }
                    : {},
                }}
                multiline={multiline}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                rows={rows}
                size="small"
                sx={{ width: "100%", ...inputStyling }}
                variant="outlined"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register(name)}
                value={watchedValue || field.value}
              />
              {additionalLowerHorizontalLabel && horizontal && (
                <Typography variant="caption" sx={{ ml: "auto", mt: 1 }}>
                  {additionalLowerHorizontalLabel}
                </Typography>
              )}
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <FormHelperText
                    error
                    sx={{ textAlign: horizontal ? "right" : "left" }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </Stack>
          )
        }}
      />
    </InputNumericFieldContainerDiv>
  )
}

export default InputNumericField
