import { styled } from "@mui/system"

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  maxWidth: "442px",
  padding: "16px 0",
})

export default FormContainer
