import * as React from "react"

import { ClerkProvider } from "@clerk/clerk-react"

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

const localization = {
  signIn: {
    start: {
      title: "Sign in to your account",
      subtitle: "Welcome back",
    },
  },
  formFieldLabel__emailAddress: "Email",
}

interface AuthProviderProps {
  children: React.ReactNode
}

const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
}: AuthProviderProps) => {
  return (
    <ClerkProvider localization={localization} publishableKey={clerkPubKey}>
      {children}
    </ClerkProvider>
  )
}

export default AuthProvider
