import * as React from "react"

import { ButtonBase, SxProps, Theme } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { colors } from "@constants/colors"

interface ButtonProps {
  children: React.ReactNode
  enabled?: boolean
  dataCy?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  sx?: SxProps<Theme>
}

// Button with minimal styling - only changes nested text
// to look like a link.
// Preferred over random element because it is accessible
const InvisibleButton: React.FC<ButtonProps> = ({
  children,
  enabled = true,
  dataCy,
  onClick,
  sx = {},
}: ButtonProps) => {
  const theme = useTheme()
  const darkMode = theme.palette.mode === "dark"

  const color = darkMode ? colors.DARK_SECONDARY : colors.SECONDARY
  const textColor = enabled ? color : "inherit"

  return (
    <ButtonBase
      disableRipple={!enabled}
      focusRipple={!enabled}
      sx={{
        color: textColor,
        cursor: enabled ? "pointer" : "default",
        fontFamily: theme.typography.fontFamily,
        width: "fit-content",
        ...sx,
      }}
      data-cy={dataCy}
      onClick={enabled ? onClick : () => {}}
    >
      {children}
    </ButtonBase>
  )
}

export default InvisibleButton
