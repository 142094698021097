function isImageLoading(url: string): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    const img = new Image()

    img.onload = () => {
      resolve(false) // Image has successfully loaded
    }

    img.onerror = () => {
      console.log("image failed to load")
      resolve(false) // Image failed to load
    }

    img.src = url
  })
}

export default isImageLoading
