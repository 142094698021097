import React from "react"

import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"

import Image from "@components/Image"
import Logo from "@components/Logo"
import ProtectedView from "@components/ProtectedView"

import DigitPreview from "./images/org-table.png"

import styles from "./Layout.module.css"

const SkeletonLayout: React.FC = () => {
  return (
    <ProtectedView>
      <Grid component="main" sx={{ height: "100vh" }}>
        <Grid className={styles.LogoWrapper}>
          <Logo className={styles.Logo} />
        </Grid>

        <Grid
          className={styles.Wrapper}
          justifyContent="space-evenly"
          sx={{ flexDirection: "row" }}
        >
          <Grid item xs={12} sm={8} md={6} flex={1} maxWidth="364px">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem className={styles.Divider} />
          <Grid item xs={12} sm={8} md={6} flex={1}>
            <Image
              src={DigitPreview}
              alt="Digit's Organization Table"
              className={styles.PreviewImage}
            />
          </Grid>
        </Grid>
      </Grid>
    </ProtectedView>
  )
}

export default SkeletonLayout
