import { RefObject, useEffect, useRef, useState } from "react"

/**
 * Check if an element is in viewport
 * @param {number} offset - Number of pixels up to the observable element from the top
 */
export default function useInView<T extends Element>(
  offset = 0,
): [boolean, RefObject<T>] {
  const [isVisible, setIsVisible] = useState(false)
  const currentElement = useRef<T>(null)

  const onScroll = () => {
    if (!currentElement.current) {
      setIsVisible(false)
      return
    }
    const { top } = currentElement.current.getBoundingClientRect()
    setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight)
  }

  useEffect(() => {
    // Call once for initial load and register for any future scrolling
    onScroll()
    document.addEventListener("scroll", onScroll, true)
    return () => document.removeEventListener("scroll", onScroll, true)
  })

  return [isVisible, currentElement]
}
