import React from "react"

import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import Typography from "@mui/material/Typography"
import { Controller } from "react-hook-form"

import { useThemeContext } from "@providers/AppThemeProvider"

import joinStyles from "@services/joinStyles"

import { colors } from "@constants/colors"

import { ReactComponent as CircleCheckIcon } from "@images/icons/circle-check-solid.svg"

import styles from "./IconCheckbox.module.css"

interface IconCheckboxProps {
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  icon: React.ReactNode
  label: string
  name: string

  defaultValue?: boolean
}

const IconCheckbox: React.FC<IconCheckboxProps> = ({
  control,
  icon,
  label,
  name,

  defaultValue,
}: IconCheckboxProps) => {
  const { darkMode } = useThemeContext()
  const selectedClass = darkMode
    ? styles.CheckboxSelectedDark
    : styles.CheckboxSelected

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Box
          component="label"
          className={joinStyles(styles.Checkbox, value ? selectedClass : "")}
          sx={{
            backgroundColor: darkMode ? colors.PRIMARY : colors.STANDARD_WHITE,
          }}
        >
          <Box>
            {icon}
            <Typography variant="body1">{label}</Typography>
          </Box>
          <Checkbox
            checked={value}
            onChange={onChange}
            id={name}
            icon={<CircleOutlinedIcon sx={{ opacity: 0 }} />}
            checkedIcon={<CircleCheckIcon />}
          />
        </Box>
      )}
    />
  )
}

export default IconCheckbox
