import React from "react"

import InfoIcon from "@mui/icons-material/Info"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "1rem",
  },
}))

interface InfoTooltipProps {
  text: string | React.ReactNode

  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({
  text,

  sx,
}: InfoTooltipProps) => {
  return (
    <HtmlTooltip title={text}>
      <InfoIcon sx={{ fontSize: "1rem", ...sx }} />
    </HtmlTooltip>
  )
}

export default InfoTooltip
