import React from "react"

import joinStyles from "@services/joinStyles"

import styles from "./Image.module.css"

interface ImageProps {
  alt: string
  src: string

  // NOT REQUIRED
  addStyles?: {
    borderRadius?: string
    height?: string
    maxHeight?: string
    maxWidth?: string
    minHeight?: string
    minWidth?: string
    width?: string
  }
  className?: string
  loading?: "eager" | "lazy"
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const Image: React.FC<ImageProps> = ({
  addStyles,
  alt,
  className = "",
  loading = "lazy",
  src,
  sx,
}: ImageProps) => {
  return (
    <div
      className={joinStyles(styles.Wrapper, className)}
      style={{ ...addStyles, ...sx }}
    >
      {/* eslint-disable-next-line react/forbid-elements */}
      <img
        loading={loading}
        src={src}
        alt={alt}
        className={styles.Image}
        style={{
          borderRadius: addStyles?.borderRadius,
        }}
      />
    </div>
  )
}

export default Image
