import React from "react"

import ViewSidebarOutlinedIcon from "@mui/icons-material/ViewSidebarOutlined"
import IconButton from "@mui/material/IconButton"
import { styled } from "@mui/material/styles"

const IconWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.primary.main : "#555",
}))

interface DrawerToggleProps {
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  toggleDrawer: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const DrawerToggle: React.FC<DrawerToggleProps> = ({
  sx,
  toggleDrawer,
}: DrawerToggleProps) => {
  return (
    <IconWrapper sx={sx}>
      <IconButton
        aria-label="toggle drawer"
        color="inherit"
        edge="start"
        onClick={toggleDrawer}
      >
        <ViewSidebarOutlinedIcon sx={{ maxHeight: "18px" }} />
      </IconButton>
    </IconWrapper>
  )
}

export default DrawerToggle
