import React from "react"

import { useThemeContext } from "@providers/AppThemeProvider"

import { ReactComponent as DigitLogoDark } from "./assets/logo-dark.svg"
import { ReactComponent as DigitIcon } from "./assets/logo-icon.svg"
import { ReactComponent as DigitLogoLight } from "./assets/logo-light.svg"
import { ReactComponent as DigitTextDark } from "./assets/logo-text-dark.svg"
import { ReactComponent as DigitTextLight } from "./assets/logo-text-light.svg"

interface LogoIconProps {
  style?: object
  className?: string
}

export const LogoIcon: React.FC<LogoIconProps> = ({
  style,
  className,
}: LogoIconProps) => {
  return <DigitIcon style={style} className={className} />
}

interface LogoTextProps {
  style?: object
  className?: string
}

export const LogoText: React.FC<LogoTextProps> = ({
  style,
  className,
}: LogoTextProps) => {
  const { darkMode } = useThemeContext()

  if (darkMode) {
    return <DigitTextLight style={style} className={className} />
  }

  return <DigitTextDark style={style} className={className} />
}

interface LogoProps {
  style?: object
  className?: string
  testId?: string
}

const Logo: React.FC<LogoProps> = ({ style, className, testId }: LogoProps) => {
  const { darkMode } = useThemeContext()
  if (darkMode) {
    return (
      <DigitLogoLight
        className={className}
        data-testid={testId}
        style={style}
      />
    )
  }

  return (
    <DigitLogoDark className={className} data-testid={testId} style={style} />
  )
}

export default Logo
