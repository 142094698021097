import React from "react"

import { ErrorMessage } from "@hookform/error-message"
import FormHelperText from "@mui/material/FormHelperText"
import MuiSwitch, { SwitchProps } from "@mui/material/Switch"
import { styled } from "@mui/material/styles"
import { Controller } from "react-hook-form"

import InfoTooltip from "@components/InfoTooltip"
import HelperText from "@components/forms/default/HelperText"
import { InputContainerDiv } from "@components/forms/default/InputTextField"
import Label from "@components/forms/default/Label"

const IOSSwitch = React.forwardRef<HTMLButtonElement, SwitchProps>(
  (props, ref) => (
    <MuiSwitch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  ),
)

IOSSwitch.displayName = "IOSSwitch"

const StyledIOSSwitch = styled(IOSSwitch)(({ theme }) => ({
  width: 32,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}))

interface PassedSwitchProps {
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  errors: any // eslint-disable-line @typescript-eslint/no-explicit-any
  label: string
  name: string
  watch: any // eslint-disable-line @typescript-eslint/no-explicit-any

  // NOT REQUIRED
  disabled?: boolean
  helperText?: string
  horizontal?: boolean
  onChange?: () => void
  required?: boolean
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  sxLabel?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  sxWrapper?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  tooltip?: string
  dataCy?: string
}

const Switch: React.FC<PassedSwitchProps> = ({
  control,
  disabled,
  errors,
  helperText,
  horizontal,
  label,
  name,
  onChange,
  required,
  sx,
  sxLabel,
  sxWrapper,
  tooltip,
  watch,
  dataCy,
}: PassedSwitchProps) => {
  const wrapperStyling = horizontal
    ? {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "9px 0 8px",
        ...sxWrapper,
      }
    : sxWrapper
  const labelStyling = horizontal
    ? {
        mb: 0,
        ...sxLabel,
      }
    : sxLabel
  const switchStyling = horizontal
    ? {
        ml: "auto",
        ...sx,
      }
    : sx

  return (
    <InputContainerDiv sx={wrapperStyling}>
      {label && (
        <Label sx={labelStyling} required={required}>
          {label} {tooltip && <InfoTooltip text={tooltip} />}
        </Label>
      )}
      {helperText && <HelperText>{helperText}</HelperText>}
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <>
              <StyledIOSSwitch
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                color="secondary"
                checked={watch(name)}
                disabled={disabled}
                onChange={onChange}
                data-cy={dataCy}
                required={required}
                size="small"
                sx={switchStyling}
              />

              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <FormHelperText error>{message}</FormHelperText>
                )}
              />
            </>
          )
        }}
      />
    </InputContainerDiv>
  )
}

export default Switch
