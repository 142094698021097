import { useContext } from "react"

import SessionContext, {
  OrganizationType,
  OrgMemberType,
} from "@providers/SessionProvider/SessionContext"

import { User } from "@gql/graphql"

import { roles } from "@constants/serverEnums"

import type { OrganizationResource } from "@clerk/types"

interface SessionData {
  clerkOrg: OrganizationResource | null | undefined
  clerkUser: any | null | undefined // eslint-disable-line @typescript-eslint/no-explicit-any
  currentUser: User | null | undefined
  isAdmin: boolean
  loadedClerk: boolean
  org: OrganizationType | null | undefined
  orgMember: OrgMemberType | null | undefined
  refetchData: () => void
  canViewReporting: boolean
  canViewWorkspaceSettings: boolean
  canViewWorkspacePeople: boolean
}

const useSession = (): SessionData => {
  const {
    clerkOrg,
    clerkUser,
    currentUser,
    loadedClerk,
    org,
    orgMember,
    refetchData,
    canViewReporting,
    canViewWorkspaceSettings,
    canViewWorkspacePeople,
  } = useContext(SessionContext)

  return {
    clerkOrg,
    clerkUser,
    currentUser,
    isAdmin: orgMember?.profile?.role?.displayName === roles.FULL_ACCESS_USER,
    loadedClerk,
    org,
    orgMember,
    refetchData,
    canViewReporting,
    canViewWorkspaceSettings,
    canViewWorkspacePeople,
  }
}

export default useSession
