import FormHelperText from "@mui/material/FormHelperText"
import { styled } from "@mui/system"

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color:
    theme.palette.mode === "dark" ? theme.palette.primary.light : "#616161",
  fontSize: "12px",
  fontWeight: "normal",
  lineHeight: "130%" /* 15.6px */,
  marginTop: 0,
  marginBottom: "8px",
}))

export default HelperText
